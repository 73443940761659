var header = document.getElementById("header-page");
window.onscroll = function () {
    var y = window.scrollY;
    if (y > 100) {
        header.classList.add('active');
    } else {
        header.classList.remove('active');
    }
};

/*Menu MOBILE Open*/
var menu = document.getElementsByClassName("container-bars");

for (var i = 0; i < menu.length; i++) {
    menu[i].addEventListener('click', function () {
        let menu = this.nextSibling.nextElementSibling
        header.classList.add('active');
        menu.classList.add('active')
    });
}

/*Menu MOBILE Close*/
var close = document.getElementsByClassName("close");

for (var i = 0; i < close.length; i++) {
    close[i].addEventListener('click', function () {
        let close = this.parentNode.parentNode
        header.classList.remove('active');
        close.classList.remove('active')
    });
}

/*Menu Sub Menu MOBILE*/
var menuMobile = document.querySelectorAll(".menu");

for (var i = 0; i < menuMobile.length; i++) {
    menuMobile[i].addEventListener('click', function (e) {
        if (this.classList.contains('active')) {
            header.classList.remove('active');
            this.classList.remove('active')
        } else {
            header.classList.add('active');
            this.classList.add('active')
        }
    });
}

var jumpToTriggers = document.querySelectorAll(".jump-to-trigger");

for (var i = 0; i < jumpToTriggers.length; i++) {
    jumpToTriggers[i].addEventListener('click', function (e) {
        jQuery('html, body').animate({scrollTop: jQuery('#' + this.dataset.id).offset().top - jQuery('#header-page').outerHeight() }, 'slow');
    });
}

function tabClick(currentTab) {
    removeActive();
    tabs[currentTab].classList.add('active');
    content[currentTab].classList.add('active');
}

function removeActive() {
    for (let i = 0; i < tabs.length; i++) {
        tabs[i].classList.remove('active');
        content[i].classList.remove('active');
    }
}

/* Footer newsletter validator */
function newsletter_validator() {
    var footer = document.querySelector('.footer .contact');
    var checkbox = document.querySelector('.footer .contact .form form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox .gchoice input');
    var emailInput = document.querySelector('.footer .contact .form form .gform_body .gform_fields .gfield .ginput_container input[type="text"]');
    var containerMsg = document.querySelector('.footer .contact .form form .gform_body .gform_fields .gfield:first-child');
    var submitBtn = document.querySelector('.footer .contact .form form .gform_footer .gform_button');

    if (footer) {
        function enableElements() {
            emailInput.disabled = false;
            emailInput.classList.remove('inactive');
            containerMsg.classList.remove('inactive');
            submitBtn.classList.remove('inactive');
            submitBtn.disabled = false;
        }

        function disableElements() {
            emailInput.disabled = true;
            emailInput.classList.add('inactive');
            containerMsg.classList.add('inactive');
            submitBtn.classList.add('inactive');
            submitBtn.disabled = true;
        }

        emailInput.addEventListener('focus', () => {
            if (checkbox.checked === false) {
                disableElements();
            } else {
                enableElements();
            }
        });

        checkbox.addEventListener('change', (e) => {
            e.preventDefault();
            if (e.target.checked) {
                enableElements();
            } else {
                disableElements();
            }
        });
    }

}

newsletter_validator();
/* End of Footer newsletter validator */
